export const metaInfo = {
    home: {
        title: "DarkLion Studio | The Brand Design Studio",
        description: "Everyone is unique. So is every idea i.e. why we don’t believe in a one-approach-fits-all! By exploring needs, we form brand experiences to be remembered.",
        keyword: "digital product design studio, digital marketing studio, digital product design agency, digital marketing agency, digital product design studio in India, digital marketing studio in India, digital product design studio in Punjab, digital marketing studio in Punjab, brand design studio, branding agency in india, brand design agency in mohali, brand design agency in punjab,brand design company in india, brand design studio in punjab, india, services as per business phases, brand design partner",
        url: "https://darklion.studio/assets/img/social/darklion-studio.jpg"
    },
    about: {
        title: "About | DarkLion Studio",
        description: "We are a team of rational and emotional thinkers. Who adventitiously gathered together to fulfill a vision, that we could impact many lives through our work.",
        keyword: "darklion studio, about darklion studio, darklions, darklionesses, digital product design, digital marketing studio",
        url: "https://darklion.studio/assets/img/social/about-darklion-studio.jpg",
    },
    darklion_aid: {

        title: "DarkLion Aid",
        description: "Explore your dream career and job opportunity at DarkLion Studio for which you are passionate & crazy about.",
        keyword: "careers, job opportunities, career opportunity, ui designer, ux designer, digital marketing executive, php developer, digital marketing internship, ux design Internship, interaction designer, ui design internship, darklion studio, job openings at darklion studio, job openings, hiring process at darklion studio, job opportunity at darklion studio",
        url: "https://darklion.studio/assets/img/aid/darklion-aid-preview.png"
    }
    ,
    careers: {
        title: "Careers at DarkLion Studio",
        description: "Explore your dream career and job opportunity at DarkLion Studio for which you are passionate about.",
        keyword: "ui designer, ux designer, digital marketing executive, php developer, digital marketing internship, ux design Internship, interaction designer, ui design internship, darklion studio, job openings at darklion studio, job openings, hiring process at darklion studio, job opportunities at darklion studio",
        url: "https://darklion.studio/assets/img/social/careers-darklion-studio.jpg"
    },

    services: {
        title: "Digital Product Design Services | DarkLion Studio",
        description: "We will help you design and shape your digital business idea at any stage of your product life-cycle - from discovery to growth.",
        keyword: "darklion studio services, digital product design strategy consultancy, Brand strategy consultancy, ui/ux audit, ui/ux design services, ux research services, branding and website design services, seo services, smm, orm, mobile app development services, mobile app development services, web app development services",
        url: "https://darklion.studio/assets/img/social/service-darklion-studio.jpg",
    },
    process: {
        title: "Process - What We Do and What We Deliver? | DarkLion Studio",
        description: "We help businesses of any scale to design desirable digital products on time and budget through our business and customer-centric design process.",
        keyword: "darklion studio process",
        url: "https://darklion.studio/assets/img/social/process-darklion-studio.jpg"
    },
    "404": {
        title: "404 Error",
        description: "The page you are looking for doesn’t exist or has been moved by DarkLions and DarkLionesses.",
        keyword: "",
        url: "https://darklion.studio/assets/img/social/darklion-studio.jpg"
    }
    ,
    "403": {
        title: "403 Error",
        description: "The page you are looking for doesn’t exist or has been moved by DarkLions and DarkLionesses.",
        keyword: "",
        url: "https://darklion.studio/assets/img/social/darklion-studio.jpg"
    },
    "500": {
        title: "500 Error",
        description: "The page you are looking for doesn’t exist or has been moved by DarkLions and DarkLionesses.",
        keyword: "",
        url: "https://darklion.studio/assets/img/social/darklion-studio.jpg"
    },
    work: {
        title: "Our Work | DarkLion Studio",
        description: "Our work tells stories about how we transform and shape ideas into reality that users love and clients desire.",
        keyword: "",
        url: "https://darklion.studio/assets/img/social/work-darklion-studio.jpg",
    },
    contact: {
        title: "Contact Us | DarkLion Studio",
        description: "We believe that - every idea has potential, and we'll help you turn it into reality. Contact us, and let's work together.",
        keyword: "",
        url: "https://darklion.studio/assets/img/social/darklion-studio.jpg"
    },
    career_digital_marketing_executive: {
        title: "Digital Marketing Executive | DarkLion Studio",
        description: "Are you a storyteller and passionate about digital marketing? Join our pack.",
        keyword: "Graphic Designer, Careers at DarkLion Studio, DarkLion Studio",
        url: "https://darklion.studio/assets/img/social/careers-darklion-studio.jpg"
    },
    career_graphic_designer: {
        title: "Visual Storyteller | Graphic Designer",
        description: "We love individuals who come prepared with research about the company and their roles, demonstrating professionalism and passion, as it can significantly impact the interview process.",
        keyword: "visual storyteller graphic designer opening at design studio, agency, visual storyteller graphic designer,visual storyteller graphic job in brand design studio, agency",
        url: "https://darklion.studio/assets/img/social/careers-darklion-studio.jpg"
    },
    career_ui_designer: {
        title: "User Interface & Interaction Designer",
        description: "We love individuals who come prepared with research about the company and their roles, demonstrating professionalism and passion, as it can significantly impact the interview process.",
        keyword: "product designer opening at design studio, agency, ui designer, interaction designer job in brand design studio, agency",
        url: "https://darklion.studio/assets/img/social/careers-darklion-studio.jpg"
    },
    career_front_end_developer: {
        title: "Front-End Developer | DarkLion Studio",
        description: "Do you love to turn pretty designs into interactive user interfaces? Join our pack.",
        keyword: "Graphic Designer, Careers at DarkLion Studio, DarkLion Studio",
        url: "https://darklion.studio/assets/img/social/careers-darklion-studio.jpg"
    },
    career_wordpress_developer: {
        title: "Wordpress Developer | DarkLion Studio",
        description: "Are you a top notch Wordpress developer? Join our pack.",
        keyword: "Wordpress Developer, Careers at DarkLion Studio, DarkLion Studio",
        url: "https://darklion.studio/assets/img/social/careers-darklion-studio.jpg"
    },
    brand_design_partner: {
        title: 'Your Brand Design Partner | DarkLion Studio',
        description: 'From ideation to research, strategy to design, and engineering to expansion, we are your dedicated partner, supporting you at every stage of your business and idea.',
        keyword: 'brand design partnership, brand strategy collaboration, creative brand alliance, creative brand consultancy, partnership in brand engineering, darklion studio program, partner with brand design studio',
        url: 'https://darklion.studio/public/assets/img/brand-partner/preview/Brand-Design-Partner.gif'
    }
}
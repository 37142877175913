import React from 'react'

import ScrollAnimation from 'react-animate-on-scroll';
const HiringProcess = () => {
    return (
        <section class="dl-block text-center">
            <div class="dl-container">
                <div class="dl-row">
                    <div class="dlxs-12 dlsm-12 dllg-12">
                        <h3>Hiring process</h3>
                        <p>We know time is precious, so we have a process.</p>
                    </div>

                    <div class="dlxs-12 dlxss-12">
                        <ul class="ul-none d-flex flex-jc text-center mT50 dlxs-mT10 dl-process-steps hiring overflow-hide">
                            <ScrollAnimation animateIn='fadeInRight' delay={100}>
                                <li>
                                    <i class="dl-icon w-80">
                                        <svg width="125" height="125" viewBox="0 0 137 137" fill="none">
                                            <circle cx="68.7164" cy="68.7882" r="47.2164" stroke="black" stroke-width="3" />
                                            <path d="M78.8155 53.4775C78.8155 58.7417 74.3522 63.1127 68.7163 63.1127C63.0804 63.1127 58.6172 58.7417 58.6172 53.4775C58.6172 48.2132 63.0804 43.8423 68.7163 43.8423C74.3523 43.8423 78.8155 48.2132 78.8155 53.4775Z" stroke="black" stroke-width="3" />
                                            <path d="M100.73 102.657L123 124.928" stroke="black" stroke-width="4" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M89.1751 87.8117C89.2431 87.1316 89.2778 86.4417 89.2778 85.7437C89.2778 74.3881 80.0724 65.1826 68.7168 65.1826C57.3613 65.1826 48.1558 74.3881 48.1558 85.7437C48.1558 86.4417 48.1905 87.1316 48.2585 87.8117H45.2453C45.186 87.1302 45.1558 86.4405 45.1558 85.7437C45.1558 72.7313 55.7044 62.1826 68.7168 62.1826C81.7292 62.1826 92.2778 72.7313 92.2778 85.7437C92.2778 86.4405 92.2476 87.1302 92.1883 87.8117H89.1751Z" fill="black" />
                                        </svg>
                                    </i>
                                    <p class="card-title"><span>1.</span> Screening</p>
                                </li>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInRight' delay={200}>
                                <li>
                                    <i class="dl-icon w-80">
                                        <svg width="125" height="125" viewBox="0 0 137 137" fill="none">
                                            <rect x="13.5" y="35.6494" width="110" height="66.7009" stroke="black" stroke-width="3" />
                                            <path d="M24.6729 49.4624L69.556 80.0886L113.383 49.4624" stroke="black" stroke-width="3" />
                                            <path d="M80.2007 55.271C80.2007 61.3845 75.0203 66.4439 68.4998 66.4439C61.9792 66.4439 56.7988 61.3845 56.7988 55.271C56.7988 49.1575 61.9792 44.0981 68.4998 44.0981C75.0203 44.0981 80.2007 49.1575 80.2007 55.271Z" stroke="black" stroke-width="3" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M92.2002 94.3453C92.2761 93.5713 92.3149 92.7864 92.3149 91.9924C92.3149 78.8399 81.6527 68.1777 68.5002 68.1777C55.3478 68.1777 44.6855 78.8399 44.6855 91.9924C44.6855 92.7864 44.7244 93.5713 44.8003 94.3453H41.7873C41.72 93.57 41.6855 92.7852 41.6855 91.9924C41.6855 77.1831 53.6909 65.1777 68.5002 65.1777C83.3096 65.1777 95.3149 77.1831 95.3149 91.9924C95.3149 92.7852 95.2805 93.57 95.2131 94.3453H92.2002Z" fill="black" />
                                        </svg>
                                    </i>
                                    <p class="card-title"><span>2.</span> Intimation</p>
                                </li>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInRight' delay={300}>
                                <li>
                                    <i class="dl-icon w-80">
                                        <svg width="125" height="125" viewBox="0 0 137 137" fill="none">
                                            <path d="M79.0204 50.4832C79.0204 59.1954 71.4887 66.4108 62.0036 66.4108C52.5185 66.4108 44.9868 59.1954 44.9868 50.4832C44.9868 41.771 52.5185 34.5557 62.0036 34.5557C71.4887 34.5557 79.0204 41.771 79.0204 50.4832Z" stroke="black" stroke-width="3" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M96.4904 104.944C96.5919 103.872 96.6438 102.787 96.6438 101.689C96.6438 82.8578 81.3783 67.5923 62.5472 67.5923C43.7162 67.5923 28.4507 82.8578 28.4507 101.689C28.4507 102.787 28.5026 103.872 28.604 104.944H25.5915C25.4983 103.871 25.4507 102.786 25.4507 101.689C25.4507 81.201 42.0594 64.5923 62.5472 64.5923C83.0351 64.5923 99.6438 81.201 99.6438 101.689C99.6438 102.786 99.5962 103.871 99.503 104.944H96.4904Z" fill="black" />
                                            <path d="M111.019 53.751L111.019 104.944" stroke="black" stroke-width="3" />
                                            <path d="M123 33.0557V104.944" stroke="black" stroke-width="3" />
                                            <path d="M14.5586 40.6802L14.5586 104.944" stroke="black" stroke-width="3" />
                                        </svg>
                                    </i>
                                    <p class="card-title"><span>3.</span> Interview rounds</p>
                                </li>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInRight' delay={500}>
                                <li>
                                    <i class="dl-icon w-80">
                                        <svg width="120" height="125" viewBox="0 0 120 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_972_2314)">
                                            <rect x="29" y="86.0753" width="67.8565" height="14.9247" stroke="black" stroke-width="4"/>
                                            <rect x="29" y="25" width="67.8565" height="59.6559" stroke="black" stroke-width="4"/>
                                            <path d="M39.1204 41.9247H63.3611M39.1204 60.8494H57.9069" stroke="black" stroke-width="4"/>
                                            <path d="M72.0186 49.5196L87.9431 66.0108L120.5 39.3441" stroke="black" stroke-width="4"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_972_2314">
                                            <rect width="120" height="125" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </i>
                                    <p class="card-title"><span>4.</span> Background Check</p>
                                </li>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInRight' delay={500}>
                                <li>
                                    <i class="dl-icon w-80">
                                        <svg width="125" height="125" viewBox="0 0 137 137" fill="none">
                                            <circle cx="68.7856" cy="68.5" r="48" stroke="black" stroke-width="3" />
                                            <path d="M69.2568 76.4897L88.4581 96.3145L127.714 64.2573" stroke="black" stroke-width="3" />
                                            <path d="M79.0714 52.9427C79.0714 58.3059 74.5247 62.757 68.7857 62.757C63.0468 62.757 58.5 58.3059 58.5 52.9427C58.5 47.5795 63.0468 43.1284 68.7857 43.1284C74.5247 43.1284 79.0714 47.5795 79.0714 52.9427Z" stroke="black" stroke-width="3" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M89.6212 87.8286C89.69 87.1377 89.7252 86.437 89.7252 85.7281C89.7252 74.1632 80.3501 64.7881 68.7852 64.7881C57.2204 64.7881 47.8452 74.1632 47.8452 85.7281C47.8452 86.437 47.8804 87.1377 47.9493 87.8286H44.9361C44.8759 87.1364 44.8452 86.4358 44.8452 85.7281C44.8452 72.5064 55.5635 61.7881 68.7852 61.7881C82.0069 61.7881 92.7252 72.5064 92.7252 85.7281C92.7252 86.4358 92.6945 87.1364 92.6343 87.8286H89.6212Z" fill="black" />
                                        </svg>
                                    </i>
                                    <p class="card-title"><span>5.</span> Selection</p>
                                </li>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInRight' delay={500}>
                                <li>
                                    <i class="dl-icon w-80">
                                        <svg width="125" height="125" viewBox="0 0 137 137" fill="none">
                                            <path d="M75.2378 56.7971C75.2378 63.0405 69.9478 68.2051 63.292 68.2051C56.6362 68.2051 51.3462 63.0405 51.3462 56.7971C51.3462 50.5538 56.6362 45.3892 63.292 45.3892C69.9478 45.3892 75.2378 50.5538 75.2378 56.7971Z" stroke="black" stroke-width="3" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M87.4871 96.5971C87.5642 95.8088 87.6037 95.0094 87.6037 94.2008C87.6037 80.7736 76.7188 69.8887 63.2916 69.8887C49.8644 69.8887 38.9795 80.7736 38.9795 94.2008C38.9795 95.0094 39.019 95.8088 39.0961 96.5971H36.0832C36.0145 95.8075 35.9795 95.0082 35.9795 94.2008C35.9795 79.1167 48.2075 66.8887 63.2916 66.8887C78.3757 66.8887 90.6037 79.1167 90.6037 94.2008C90.6037 95.0082 90.5687 95.8075 90.5 96.5971H87.4871Z" fill="black" />
                                            <path d="M16.5005 103.051V25.0649L107.932 25.0649V103.051" stroke="black" stroke-width="3" />
                                            <path d="M38.0137 115.959L88.5699 115.959" stroke="black" stroke-width="3" />
                                            <path d="M90.4238 61.232L97.5349 68.343M108.113 18.9679L116 11.0811M26.2681 61.232L31.6269 55.7886L43.3926 67.5542L38.5509 72.4804L26.2681 61.232ZM95.9834 94.5894L90.4238 100.149L95.9834 105.709L101.543 100.149L95.9834 94.5894Z" stroke="black" stroke-width="3" />
                                        </svg>
                                    </i>
                                    <p class="card-title"><span>6.</span> Onboarding</p>
                                </li>
                            </ScrollAnimation>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HiringProcess
import './assets/css/dl-theme.min.css';
import './assets/css/dl-plugins-min.css';
import './assets/css/main.css';
import './assets/css/dl-media.css';
import HomePage from './pages/HomePage';
import About from './pages/About';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Process from './pages/Process';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
import Services from './pages/Services';
import OurWork from './pages/OurWork';
import DarklionAid from './pages/DarklionAid';
import BrandDesignPartner from './BrandDesignPartner/BrandDesignPartner';
import BrandDesignPartnerHeader from './BrandDesignPartner/components/Header/Header';
import BrandDesignPartnerFooter from './BrandDesignPartner/components/Footer/Footer';
import Frontend from './pages/CareerInner/Frontend';
import gsap from 'gsap';
import UiDesigner from './pages/CareerInner/UiDesigner';
import GraphicDesigner from './pages/CareerInner/GraphicDesigner';
import DigitalMarketing from './pages/CareerInner/DigitalMarketing';
import Wordpress from './pages/CareerInner/Wordpress';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  const location = useLocation();
  const cursorRef = useRef(null);
  const [customCursorClass, updateCustomCursorClass] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [belowButtonCursorLink, setBelowButtonCursorLink] = useState('');
  const [blendModeOnBDP, setBlendModeOnBDP] = useState(false);
  const handleSameLocationOnClick = (currentLocation) => {

    if (currentLocation === location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  const onMouseEnterBelowButton = () => {
    console.log("Hitted");

    setBelowButtonCursorLink('custom-cursor--link');
  }
  const onMouseLeaveBelowButton = () => {
    console.log("Hitted");

    setBelowButtonCursorLink('');
  }

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalVisible(prevState => !prevState);
  };

  const onMouseEnterFunc = () => {
    updateCustomCursorClass('cursor-without-shadow')
    setBlendModeOnBDP(true)
  }
  
  const onMouseOutFunc = () => {
    updateCustomCursorClass('')
    setBlendModeOnBDP(true)
  }

  const mouseInFaqFunction = () => {
    updateCustomCursorClass('cursor-hover')
  }

  const mouseFaqOutFunction = () => {
    updateCustomCursorClass('')
  }

  const [stickyNavbarTopPosition, setStickyNavbarTopPosition] = useState(false);

  useEffect(() => {
    // Scroll handling function
    let lastScrollTop = 0;
    let navbarDynamicHeight = 0;
    const handleScroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      const navbarHeight = 100;
      const delta = 5;


      if (Math.abs(lastScrollTop - st) <= delta)
        return;


      if (st > lastScrollTop && st > navbarHeight) {
        // Scrolling down      
        document.querySelector('header').classList.remove('nav-down');
        document.querySelector('header').classList.add('nav-up');
        if (location.pathname === '/process' || location.pathname === '/services') {
          document.querySelector('.sticky-sub-nav').classList.remove('sub-nav-down');
          document.querySelector('.sticky-sub-nav').classList.add('sub-nav-up');
        }
      } else {
        // Scrolling up or at the top
        if (st + window.innerHeight < document.documentElement.scrollHeight) {
          document.querySelector('header').classList.remove('nav-up');
          document.querySelector('header').classList.add('nav-down');
          if (location.pathname === '/process' || location.pathname === '/services') {
            document.querySelector('.sticky-sub-nav').classList.remove('sub-nav-up');
            document.querySelector('.sticky-sub-nav').classList.add('sub-nav-down');
          }
        }
      }

      lastScrollTop = st;
    };

    // Attach scroll event listener only on '/process' and '/services' paths
    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === '/brand-design-partner') {

      const onMouseMove = (e) => {
        gsap.set(cursorRef.current, {
          x: e.clientX,
          y: e.clientY,
          opacity: 1
        });
      };

      document.addEventListener('mousemove', onMouseMove);

      return () => {
        document.removeEventListener('mousemove', onMouseMove);
      };
    }
  }, []);

  useEffect(() => {
    // Add 'brand-partner' class to body when on '/brand-design-partner' route
    if (location.pathname === '/brand-design-partner') {
      document.body.classList.add('brand-partner');
    } else {
      document.body.classList.remove('brand-partner');
    }

    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const renderHeader = () => (
    location.pathname === '/brand-design-partner' ? <BrandDesignPartnerHeader modalVisibleVar={isModalVisible} modalClick={toggleModal} mouseFunction={onMouseEnterFunc} mouseOutFunction={onMouseOutFunc} /> : <Header />
  );

  const renderFooter = () => (
    location.pathname === '/brand-design-partner' ? <BrandDesignPartnerFooter /> : <Footer clickHandler={handleSameLocationOnClick} />
  );

  return (
    <div className="App">
      {renderHeader()}
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<About />} />
        <Route path='/process' element={<Process />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/services' element={<Services stickyNavbarTopPosition={stickyNavbarTopPosition} />} />
        <Route path='/work' element={<OurWork />} />
        <Route path='/darklion-aid' element={<DarklionAid />} />
        <Route path='/brand-design-partner' element={<BrandDesignPartner mouseInFaqFunction={mouseInFaqFunction} mouseFaqOutFunction={mouseFaqOutFunction} onMouseEnterBelowButton={onMouseEnterBelowButton} modalVisibleVar={isModalVisible} modalClick={toggleModal} mouseFunction={onMouseEnterFunc} mouseOutFunction={onMouseOutFunc} />} />
        <Route path='/careers/front-end-developer' element={<Frontend />} />
        <Route path='/careers/ui-designer' element={<UiDesigner />} />
        <Route path='/careers/visualstoryteller-graphicdesigner' element={<GraphicDesigner />} />
        <Route path='/careers/career-digital-marketing' element={<DigitalMarketing />} />
        <Route path='/careers/career-wordpress-developer' element={<Wordpress />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
      {location.pathname === '/brand-design-partner' ? <div ref={cursorRef} style={{ mixBlendMode: blendModeOnBDP ? 'difference' : 'normal' }} className={" custom-cursor cursor-with-shadow " + customCursorClass + ' ' + belowButtonCursorLink}></div> : ''}
      {renderFooter()}
    </div>
  );
}

export default App;

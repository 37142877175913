import React, { Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import HiringProcess from "../../components/HiringProcess/HiringProcess";
import FootCta from "../../components/FootCta/FootCta";

import TeamImg from "../../assets/img/team/Aces.png";
import Img from "../../assets/img/team/keshav.png";
import { Link } from "react-router-dom";
import { MetaInfo } from '../../components/Seo/MetaInfo.jsx'


function UiDesigner() {
    return (

        <Fragment>
            <MetaInfo page_name="career_ui_designer" />

            <section class="dl-page-banner dl-job-banner aces dl-block dl-block-dark text-center">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-12 dllg-12">
                            <ScrollAnimation animateIn="fadeInUp" duration={2} delay={100}>
                                <h1 class="mT0 mB20 font-w-600">UI & Interaction Designer</h1>
                                <p class="font-l">If you are creative, passionate about creating user-centric designs and have a portfolio that demonstrates your skills and experience, we would love to hear from you.</p>
                            </ScrollAnimation>
                        </div>

                        <ul class="dl-breadcrumb">
                            <li class="hidden-dlxs"><Link to="/">Darklion Studio </Link></li>
                            <li class="hidden-dlxs"><Link to="/careers">Careers </Link></li>
                            <li class="hidden-dlxs">UI & Interaction Designer</li>
                            <li class="visible-dlxs">
                                <Link to="/careers" class="arrow">
                                    <svg width="40" height="40" viewBox="0 0 61 61" fill="none">
                                        <path d="M37.7988 50L58.7988 29" stroke="white" stroke-width="4"></path>
                                        <path d="M37 10L58 31" stroke="white" stroke-width="4"></path>
                                        <path d="M1 30.2012L58 30.2012" stroke="white" stroke-width="4"></path>
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section class="dl-block dl-job-detail">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dllg-8 dlmd-7 dlsm-12 dlxs-12 text-left">
                            <h4 class="mB20">About designation</h4>
                            <p>We seek a talented User Interface (UI) Designer to create exceptional user experiences for products, software, and websites. The ideal candidate should have an eye for clean and artful design, possess UI skills, and be able to translate high-level requirements into interaction flows and artifacts, transforming them into intuitive and functional user interfaces. You will work closely with our product managers, developers, and other designers to deliver high-quality UI designs that enhance user experience and align with our brand identity.</p>
                            <br />
                            <br />
                            <h4 class="mB20">We Will Onboard You If You Have Prior Experience In</h4>
                            <ul class="font-n dl-list">
                                <li>Designing user interfaces for digital products, software, and websites.</li>
                                <li>Working with cross-functional teams to deliver end-to-end UI solutions.</li>
                                <li>Implementing and adhering to UI design standards, principles, and guidelines.</li>
                                <li>Knowledge or bit of experience related to Design Systems.</li>
                            </ul>
                            <br />
                            <br />
                            <h4 class="mB20">Skills & Responsibilities</h4>
                            <p><b>Key Responsibilities</b></p>
                            <ul class="font-n dl-list">
                                <li>UI Design & Interaction Design: Design intuitive and aesthetically pleasing user interfaces for various digital products, software, and websites.</li>
                                <li>Wire-framing: Create wireframes, storyboards, user flows, process flows, and site maps to effectively communicate interaction and design ideas. </li>
                                <li>Prototyping: Develop UI mockups, drafts and prototypes that clearly illustrate how sites function and look to present to internal teams and key stakeholders.</li>
                                <li>User Research: Collaborate in user research and evaluate user feedback to improve designs.</li>
                                <li>Design Systems: Develop and maintain design systems, style guides, and patterns to ensure consistency across all platforms.</li>
                                <li>Stay Updated: Keep up-to-date with the latest design trends, tools, and technologies to ensure our designs are cutting-edge and competitive.</li>
                            </ul>
                            <p><b>Skills</b></p>
                            <ul className="font-n dl-list">
                                <li>Excellent communication and collaboration skills.</li>
                                <li>Proficiency in design software (e.g., Sketch, Figma, Adobe XD, Photoshop, Illustrator).</li>
                                <li>Problem solving ability to design with minimalistic approach.</li>
                                <li>Familiarity with front-end development technologies (HTML, CSS, JavaScript) is a plus.</li>
                                <li>Knowledge of accessibility standards and best practices.</li>
                            </ul>
                            <br />
                            <br />
                            <h4 class="mB20">We'll be even happier, if you are:</h4>
                            <ul class="font-n dl-list">
                                <li><b>Passionate</b> about creating innovative and user-friendly digital experiences </li>
                                <li><b>Adaptable</b></li>
                                <li><b>Creative thinker</b></li>
                                <li><b>Familiar</b> with HTML, CSS, and JavaScript</li>
                                <li><b>Knowledgeable</b> about accessibility standards and best practices</li>
                                <li><b>Experienced</b> in designing for both web and mobile platforms</li>
                            </ul>
                            <br />
                            <img src={TeamImg} alt="DarkLion Studio Aces" class="dl-img-responsive mT50" />
                            {/* <p><i>*Note: Our preference is to work from the office*</i></p> */}
                            <div class="dl-cta mT30">
                                <Link to="https://forms.gle/QWerA72tY6nApZ1U9" target="_blank" title="Apply Now" class="dl-button primary dl-hover">
                                    Apply Now
                                    <i>
                                        <svg width="20px" height="20px" viewBox="0 0 268.832 268.832">
                                            <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
                                    c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
                                    c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"></path>
                                        </svg>
                                    </i>
                                </Link>
                            </div>
                        </div>

                        <div class="dllg-4 dlmd-5 dlsm-12 dlxs-12 text-left">
                            <div class="dl-job-summary-card p40 dlsm-mT20">
                                {/* <div class="d-flex mB10">
                                    <i class="w-50">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 30 30" fill="none">
                                            <path d="M5.27881 5.25537L14.9133 24.7114L24.8283 5.25537" stroke="black" stroke-width="1"></path>
                                            <path d="M2.84668 12.3689L26.9796 12.3689" stroke="black" stroke-width="1"></path>
                                        </svg>
                                    </i>

                                    <div class="font-l">
                                        <b>Department:</b>
                                        <p>Design</p>
                                    </div>
                                </div> */}
                                <div class="d-flex mB10">
                                    <i class="w-50">
                                        <svg width="40" height="40" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M69.9433 100.02L38.5052 116L43.8496 82.4733L18.3848 57.72L54.5386 51.7667L69.9433 22L85.348 51.7667L119.616 57.72L94.465 82.4733L99.4951 116L69.9433 100.02Z" stroke="black" stroke-width="5" />
                                        </svg>
                                    </i>

                                    <div class="font-l">
                                        <b>Experience:</b>
                                        <p>3+ years</p>
                                    </div>
                                </div>
                                {/* <div class="d-flex mB10">
                                    <i class="w-50">
                                        <svg width="40" height="40" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M111.753 62.1235C111.753 69.2953 105.673 75.247 97.9998 75.247C90.3261 75.247 84.2461 69.2953 84.2461 62.1235C84.2461 54.9518 90.3261 49 97.9998 49C105.673 49 111.753 54.9518 111.753 62.1235Z" stroke="black" stroke-width="5" />
                                            <path d="M100.5 75.0009C110 74.5034 129.5 83 128.5 108.499" stroke="black" stroke-width="5" />
                                            <path d="M9 109C9.22222 94.6825 17.5333 66.0476 49 66.0476C61.6667 65.3766 86.3333 71.4162 89 109" stroke="black" stroke-width="5" />
                                            <path d="M67.6757 47.8848C67.6757 57.6667 59.3915 65.7344 48.999 65.7344C38.6064 65.7344 30.3223 57.6667 30.3223 47.8848C30.3223 38.1029 38.6064 30.0352 48.999 30.0352C59.3915 30.0352 67.6757 38.1029 67.6757 47.8848Z" stroke="black" stroke-width="5" />
                                        </svg>
                                    </i>

                                    <div class="font-l">
                                        <b>Hierarchy Level:</b>
                                        <p>Senior</p>
                                    </div>
                                </div> */}

                                {/* <div class="d-flex mB10">
                                    <i class="w-50">
                                        <svg width="40" height="40" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="68.5" cy="68.5" r="50" stroke="black" stroke-width="5" />
                                            <path d="M78.5 52C78.5 57.1515 74.12 61.5 68.5 61.5C62.88 61.5 58.5 57.1515 58.5 52C58.5 46.8485 62.88 42.5 68.5 42.5C74.12 42.5 78.5 46.8485 78.5 52Z" stroke="black" stroke-width="5" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M88.7709 88.9997C88.8504 88.2679 88.8912 87.5246 88.8912 86.7718C88.8912 75.5102 79.7619 66.3809 68.5003 66.3809C57.2387 66.3809 48.1094 75.5102 48.1094 86.7718C48.1094 87.5246 48.1502 88.2679 48.2297 88.9997H43.2058C43.142 88.2655 43.1094 87.5225 43.1094 86.7718C43.1094 72.7488 54.4773 61.3809 68.5003 61.3809C82.5233 61.3809 93.8912 72.7488 93.8912 86.7718C93.8912 87.5225 93.8586 88.2655 93.7948 88.9997H88.7709Z" fill="black" />
                                        </svg>
                                    </i>

                                    <div class="font-l">
                                        <b>Age Preference:</b>
                                        <p>23-28</p>
                                    </div>
                                </div> */}

                                <div class="d-flex mB10">
                                    <i class="w-50">
                                        <svg width="40" height="40" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="68.5" cy="68.5" r="48.5" stroke="black" stroke-width="5" />
                                            <path d="M51.666 44.9336H66.6239M93.0279 44.9336H66.6239M51.666 61.6418H80.5413M93.0279 61.6418H80.5413M66.6239 44.9336C71.2631 45.723 80.5413 50.1697 80.5413 61.6418M80.5413 61.6418C80.8882 66.0272 77.4197 77.6923 63.6324 77.6923H54.2674L80.5413 103.61" stroke="black" stroke-width="5" />
                                        </svg>
                                    </i>

                                    <div class="font-l">
                                        <b>Salary:</b>
                                        <p>Competitive</p>
                                    </div>
                                </div>

                                <div class="d-flex mB10">
                                    <i class="w-50">
                                        <svg width="40" height="40" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="35" y="19" width="67" height="100" stroke="black" stroke-width="5" />
                                            <rect x="50" y="90" width="14" height="29" stroke="black" stroke-width="5" />
                                            <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 71)" stroke="black" stroke-width="5" />
                                            <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 43)" stroke="black" stroke-width="5" />
                                            <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 71)" stroke="black" stroke-width="5" />
                                            <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 99)" stroke="black" stroke-width="5" />
                                            <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 43)" stroke="black" stroke-width="5" />
                                        </svg>
                                    </i>

                                    <div class="font-l">
                                        <b>Type:</b>
                                        <p>Full-time</p>
                                    </div>
                                </div>

                                <div class="d-flex mB10">
                                    <i class="w-50">
                                        <svg width="40" height="40" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="68.5002" cy="49.0608" r="8.26" stroke="black" stroke-width="5" />
                                            <circle cx="68.5" cy="48.543" r="38.5" stroke="black" stroke-width="5" />
                                            <path d="M69.0859 89.043L69.0859 128.956" stroke="black" stroke-width="5" />
                                        </svg>
                                    </i>

                                    <div class="font-l">
                                        <b>Location:</b>
                                        <p>Mohali, on-site</p>
                                    </div>
                                </div>

                                

                               

                                <Link target="_blank" to="https://forms.gle/QWerA72tY6nApZ1U9" title="Apply Now" class="dl-button primary dl-hover mT20 dlxss-block">
                                    Apply Now
                                    <i>
                                        <svg width="20px" height="20px" viewBox="0 0 268.832 268.832">
                                            <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
                                    c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
                                    c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"></path>
                                        </svg>
                                    </i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="hiring-process-dark">
                <HiringProcess />
            </div>

            <section class="dl-block dl-testimonials">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12">
                            <div class="testimonial">
                                <blockquote class="dlxss-center m0 p0">
                                    <i class="dl-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="42" viewBox="0 0 60 42" fill="none">
                                            <path d="M4.28571 42H17.1429L25.7143 25.2V0H0V25.2H12.8571L4.28571 42ZM38.5714 42H51.4286L60 25.2V0H34.2857V25.2H47.1429L38.5714 42Z" fill="black" />
                                        </svg>
                                    </i>
                                    <div class="content dlxs-mT20 mB50 dlxss-mB20">
                                        DarkLion Studio is where I started my new career journey in design after switching from development. It was a challenging transition, as I only knew I wanted to be a designer. However, our Design Director's guidance helped me to clarify my vision and set my goals accordingly. Joining DarkLion Studio was the best decision I ever made, and I pursue my dream from there.
                                    </div>

                                    <ul class="ul-none d-flex dlxss-flex-col dlxss-p0 dlxss-center">
                                        <li class="dlxss-p0 pR30"><img src={Img} alt="Anwar Ali Brand Management Intern" /></li>
                                        <li class="dlxss-p0">
                                            <h6>Keshav Rohilla</h6>
                                            <p>UX Designer</p>
                                        </li>
                                    </ul>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="dl-block dl-block-dark dl-block-cta d-flex flex-jc dlxs-flex-col dlsm-p0 dlxs-p0 dlxss-center">
                <Link to="/careers" title="See career opportunities" class="d-flex flex-jc left anchor-none dlxss-flex-col">
                    <div class="text">
                        <p>Not looking for this job? See</p>
                        <div class="dl-h4-title">Career opportunities</div>
                    </div>

                    <span class="arrow">
                        <svg width="40" height="40" viewBox="0 0 61 61" fill="none">
                            <path d="M37.7988 50L58.7988 29" stroke="white" stroke-width="4" />
                            <path d="M37 10L58 31" stroke="white" stroke-width="4" />
                            <path d="M1 30.2012L58 30.2012" stroke="white" stroke-width="4" />
                        </svg>
                    </span>
                </Link>

                <Link to="/about" title="Read our story" class="d-flex right flex-jc anchor-none dlxs-p0 dlxss-center dlxss-flex-col">
                    <div class="text">
                        <p>What’s our company about?</p>
                        <div class="dl-h4-title">Read our story</div>
                    </div>

                    <span class="arrow">
                        <svg width="40" height="40" viewBox="0 0 61 61" fill="none">
                            <path d="M37.7988 50L58.7988 29" stroke="white" stroke-width="4" />
                            <path d="M37 10L58 31" stroke="white" stroke-width="4" />
                            <path d="M1 30.2012L58 30.2012" stroke="white" stroke-width="4" />
                        </svg>
                    </span>
                </Link>
            </section>

            <FootCta />

        </Fragment>

    );
}

export default UiDesigner